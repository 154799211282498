import React from 'react';
import { 
    Box, 
    Heading, 
    Accordion, 
    AccordionItem, 
    AccordionButton, 
    AccordionPanel, 
    AccordionIcon, 
    ListItem, 
    UnorderedList,
    Text
} from '@chakra-ui/react';
import './fonts/fonts.css';

const applicationQuestions = [
    {
        question: "Why does CYLC have a Two-step Application Process?",
        answer: "In past years, CYLC has received more than 1000 applications during the Step 1 application and accepted the first 400 based on their timestamp, who were then notified to submit the Step Two Acceptance Package. To save all parties’ time and effort, CYLC has adopted this two-step process to timestamp all Step 1 Applications and ensure that all applicants have a fair opportunity to apply and attend."
    },
    {
        question: "How quickly do spots run out?",
        answer: "It depends on how many applicants apply for each age team. In past years, slots ran out within 2 minutes."
    },
    {
        question: "How many campers will CYLC take? How many groups and what is the group size?",
        answer: "CYLC will take 400 applicants, including assistant counselors and counselors.  There are anywhere between eight to ten teams, and each team has on average 25-35 campers (balanced by gender)."
    },
    {
        question: "Which website should I go to for the Step 1 Application, or will the link be distributed through email?",
        answer: "Please check on www.cylc-dfw.org and go to the Application tab to find the information. You may also refer to CYLC’s Facebook page to obtain the updated information. CYLC will send an email reminder, but it is your responsibility to check the website regularly for details or updates as changes may occur during the process."
    },
    {
        question: "Is the Step 1 Application reviewed on a first-come-first-serve basis?",
        answer: (
            <>
                Yes, Step 1 Applications are sorted by timestamp and reviewed on a “first-come first-serve” basis. The application for most grade levels was filled in less than 2 minutes in past years.  It is important to have all necessary information in hand to complete the application. Information requested will include:
                <UnorderedList>
                    <ListItem>Parent Email</ListItem>
                    <ListItem>Camper First Name</ListItem>
                    <ListItem>Camper Last Name</ListItem>
                    <ListItem>Camper Age</ListItem>
                    <ListItem>Camper Gender</ListItem>
                    <ListItem>Camper Current Grade (for 2024-2025 School Year)</ListItem>
                    <ListItem>Secondary Email Address (e.g other parent email or camper's email)</ListItem>
                    <ListItem>Whether you are planning to apply for counselor</ListItem>
                </UnorderedList>
            </>
        )
    },
    {
        question: "When will the Step 1 Applications be available online?",
        answer: "Step 1 Applications open on Monday, 1/13/25 at 8:00pm (Central Standard Time). Registration is open to all applicants who are at least 10 years old by 8/31/2025. The Assistant Counselor application is open to all who are at least 15 years old by 8/31/2025. *Please be honest when filling out the birthday and grade level on the application.  CYLC reserves the right to reject any application with false information. CYLC reserves the right to verify an applicant’s birthday or school grade level, as any “incorrect” birthday or grade level may cause disqualification.  CYLC may request the birth certificate or school report card as a reference."
    },
    {
        question: "Is the applicant IN once I complete the Step 1 Application?",
        answer: "No, completing the Step 1 Application is only the first step.  Once you receive an Acceptance Email from CYLC, please submit the Step 2 Acceptance Package and check on or before Monday, 2/3/25."
    },
    {
        question: "May I send in the Acceptance Package directly without first completing Step 1?",
        answer: "No, Step 2 Acceptance Packages submitted without first completing the Step 1 process and receiving an Acceptance Email will NOT be accepted and will not constitute a valid application.  Please wait until you receive the Acceptance Email and then submit the Step 2 Acceptance Package."
    },
    {
        question: "How soon should I turn in my Acceptance Package?",
        answer: "Please wait until you receive the Acceptance Email from CYLC. Then complete the Step 2 Acceptance Package by following the instructions in the Google Form and mailing the camp fee check of $730 to CYLC, P.O. Box 868156, Plano, TX 75086.  All Step 2 Google Form Applications must be complete and mailed checks must be postmarked on or before Monday, 2/3/25."
    },
    {
        question: "Will I get a confirmation after completing the Step 1 and Step 2 Applications?",
        answer: "You will receive email copies of your Google form responses after submitting both Step 1 and Step 2.  Please save those email copies of your responses for your records.  If you are accepted after Step 1, CYLC will email you an Acceptance Email and the link to the Step 2 Application. You will also receive an email notice if your age group is full and you are put on the applicant waitlist."
    },
    {
        question: "I messed up my application; can I make a change without affecting whether or not I get in?",
        answer: "It is your responsibility to follow the application process and carefully check all the documents before submission.  Any mistakes may adversely affect the processing of your application, especially for contact information such as email address and cell numbers. The uniform size will also be based on what is recorded on the application."
    },
    {
        question: "Is there a place I can specify who I want to room with during camp?",
        answer: "Yes, please specify who you want to room with during camp.  CYLC will assign a roommate for you if your roommate did not get in or you did not specify a roommate in your Step 2 Application Form.  You may specify who you do not want to room with as well, but there is no guarantee either way.  We will do our best to accommodate all requests."
    },
    {
        question: "Can I pay the camp fee online? For example by Paypal or credit card? What method of payment will be accepted for the camp?",
        answer: "No, online payments are not accepted. Only personal checks and money orders are accepted for camp. Any returned check penalty will be paid by the applicant. This check will be mailed in as part of the Step 2 Application."
    },
    {
        question: "May I register the camper after the application week?",
        answer: "Yes. If there are additional camper spots available after Monday, 1/20/25, CYLC may keep the Step 1 Application Form open past the deadline of Monday, 1/20/25."
    }
];

const counselorQuestions = [
    {
        question: "Do I need to do the Step 1 and Step 2 Applications if I am applying to be an assistant counselor (AC) or counselor (C)?",
        answer: "Yes, assistant counselor and counselor applicants all need to complete the Step 1 and Step 2 applications in order to secure their room at camp. You may only apply online for counselor (C) or assistant counselor (AC) after completing the Step 1 and Step 2 Applications. We have limited spots; there is no privilege for past counselors or assistant counselors in the application.  Please follow the process carefully as instructed.  You must mark that you are applying for counselor or assistant counselor in your Step 1 Application, so that your Acceptance Email will include both a link to the Step 2 Acceptance Package Google Form and a link to the C/AC Application Google Form."
    },
    {
        question: "How can I submit my counselor application package?",
        answer: "We have set up a Google Form for you to submit your question answers, resume, essay, and code of conduct. We will email the link to you directly after you are accepted through Step 1."
    },
    {
        question: "How long do my answers to the questions need to be?",
        answer: "Answers should be concise and clear. We are looking for creative ideas and practical solutions."
    },
    {
        question: "How long does my essay need to be?",
        answer: "The essay is 250-650 words. Longer is not better as long as you adequately address the prompts."
    },
    {
        question: "Are grades/rank a major factor in selecting C/ACs? What factors are considered?",
        answer: "No, CYLC provides equal opportunities for all candidates. The school ranking/grades are not the major factors for selection. CYLC is looking for the leadership traits of creativity, confidence, integrity, positive attitude, and behavior. Most important is being a good team player and a good role model for campers."
    },
    {
        question: "I have absolutely no talent for dancing. How will this affect my candidacy?",
        answer: "CYLC is not a dance camp, but dance is a fun way to present your teamwork skills.  Your actual skill level will not affect your counselor selection, but it is important to keep a positive attitude as that will be considered more heavily during the review process.  "
    },
    {
        question: "How can I improve my application?",
        answer: "Be yourself.  CYLC does not have any cookie-cutter requirements in its counselor candidates.  Each candidate will be considered individually and evaluated holistically based on his or her individual strengths and weaknesses.  Pay attention to details and make your application look professional (e.g., make sure your application, resume, essay and picture are nice and clean). Each interview is 5-10 minutes long.  Let the teachers get to know you, and you may always seek more practice by asking your parents for tips or speaking with past counselors for additional advice prior to the interview."
    },
    {
        question: "If I don’t make C/AC will I be a camper?",
        answer: "Yes, you will keep your spot as a camper. The ratio of C/AC selection rate is historically about 50%, but this is a great opportunity for you to prepare yourself early for college applications and the interview process. Every step of the C/AC application process is important. CYLC hopes this experience can help you build up confidence and also polish other leadership skills."
    },
    {
        question: "I have never been to CYLC camp before; can I still apply to be a counselor?",
        answer: "Yes, you have the same opportunities as other candidates who have attended CYLC before."
    },
    {
        question: "What is the counselor training schedule for 2025?",
        answer: (
            <UnorderedList>
                <ListItem>The Counselor application due date is Friday, 2/14/25. You will be notified of your interview time by Friday, 2/21/25. If you cannot make your scheduled time, you must arrange yourself to switch with another candidate and notify CYLC at (cylcdfw@gmail.com) by Sunday, 2/23/25. </ListItem>
                <ListItem>Counselor interviews: Saturday, 3/1/25 - Sunday, 3/2/25</ListItem>
                <ListItem>Meet & Greet: Saturday, 3/29/25</ListItem>
                <ListItem>Counselor Trainings: 6/21-6/22, 6/28-6/29, 7/12</ListItem>
                <ListItem>Move-in date: Sunday, 7/13 </ListItem>
            </UnorderedList>
        )
    },
    {
        question: "Am I required to attend all trainings? If I have an unscheduled emergency and I miss more trainings than allowed, will I be disqualified?",
        answer: (
            <>
                <Text> CYLC counselor training is very important.  Please arrange your schedule accordingly so as not to conflict with the counselor training schedule, especially for new assistant counselors.  Missing any session will make it hard for you and your team to evenly divide the workload and will be unfair to other counselors. </Text>
                <Text> Counselors and Assistant Counselors may only be excused from a maximum of two trainings, however the training sessions on July 12-13 are mandatory. With the exception of illness and emergencies, all excused absences must be disclosed in advance and preapproved by the CYLC committee. C/ACs with any unexcused absences or who miss a training not due to special circumstances (e.g., illness or emergencies) will be removed from the C/AC team. Tardiness or early departure is considered an absence. Reasonable exceptions will be considered on a case-by-case basis.</Text>
            </>
        )
    }
];


const ApplicationFAQ = () => {
    return (
        <Box bg="FFFFEA" p={"5%"} maxWidth="90%" mx="auto">
            <Heading fontSize="3vw" textAlign="center" mb={"4%"} fontFamily={"Nohemi"}>
                Frequently Asked Application Questions
            </Heading>
            <Accordion allowToggle fontSize={{ base: "2vw", lg: "1.5vw", "2xl": "1vw" }} fontFamily={"NohemiLight"}>
                {applicationQuestions.map((item, index) => (
                    <AccordionItem key={index}>
                        <AccordionButton>
                            <Box flex="1" textAlign="left" fontWeight="bold" fontSize={{ base: "2vw", lg: "1.5vw", "2xl": "1vw" }}>
                                {item.question}
                            </Box>
                            <AccordionIcon />
                        </AccordionButton>
                        <AccordionPanel pb={4}>{item.answer}</AccordionPanel>
                    </AccordionItem>
                ))}
            </Accordion>

            <Heading fontSize="3vw" textAlign="center" mb={"4%"} mt={"4%"} fontFamily={"Nohemi"}>
                Counselor/Assistant Counselor Questions
            </Heading>
            <Accordion allowToggle fontSize={{ base: "2vw", lg: "1.5vw", "2xl": "1vw" }} fontFamily={"NohemiLight"}>
                {counselorQuestions.map((item, index) => (
                    <AccordionItem key={index}>
                        <AccordionButton>
                            <Box flex="1" textAlign="left" fontWeight="bold" fontSize={{ base: "2vw", lg: "1.5vw", "2xl": "1vw" }}>
                                {item.question}
                            </Box>
                            <AccordionIcon />
                        </AccordionButton>
                        <AccordionPanel pb={4}>{item.answer}</AccordionPanel>
                    </AccordionItem>
                ))}
            </Accordion>
        </Box>
    );
};

export default ApplicationFAQ;

import React from 'react';
import { Box, Heading,HStack, VStack, Image, Text } from '@chakra-ui/react';
import AboutFAQ from './AboutFAQ';
import './fonts/fonts.css';

const AboutInfo = () => {
    return (
        <Box
        width={"100%"}
        >
            <HStack
            bg={"#FF5E5B"}
            width={"100%"}
            p={{ base: "5%", lg: "5%", "2xl": "5%" }}
            height={{ base: "40vh", lg: "90vh", "2xl": "80vh" }}
            mt={"5%"}
            >
                <VStack
                width={"40%"}
                p={{ base: "5%", lg: "5%", "2xl": "5%" }}
                >
                    <Heading
                    fontSize={{ base: "4vw", lg: "4.5vw", "2xl": "3.5vw" }}
                    fontFamily="MeshedDisplay"
                    >
                        Welcome to CYLC
                    </Heading>
                    <Text
                    fontSize={{ base: "1.8vw", lg: "1.2vw", "2xl": "1.25vw" }}
                    fontFamily={"NohemiLight"}
                    color={"white"}
                    >
                        CYLC (Community Youth Leadership Camp) was started in 2003 by a group of volunteer teachers and parents. Its aim is to inspire Asian American youth through leadership training, group activities, and cultural programs. In addition to these programs, CYLC also serves as a community where students foster friendships that carry well into their college and professional lives.
                    </Text>
                </VStack>
                <Image src='https://cdn.testing-cylc-dfw.online/WholeCamp2024.jpg' alt='About Us' width={"60%"}/>
            </HStack>

            <HStack
            bg={"#FFFFEA"}
            width={"100%"}
            p={{ base: "5%", lg: "5%", "2xl": "5%" }}
            height={{ base: "40vh", lg: "90vh", "2xl": "80vh" }}
            >
                <Image width={"60%"} src='https://cdn.testing-cylc-dfw.online/Leadership-2.jpg'/>
                <VStack
                width={{ base: "40%", lg: "40%", "2xl": "40%" }}
                p={{ base: "5%", lg: "5%", "2xl": "5%" }}
                >
                    <Heading
                    fontFamily={"MeshedDisplay"}
                    fontSize={{ base: "4vw", lg: "4.5vw", "2xl": "3.5vw" }}
                    >
                        This is Our Mission
                    </Heading>
                    <Text
                    fontFamily={"NohemiLight"}
                    fontSize={{ base: "2vw", lg: "1.5vw", "2xl": "1.25vw" }}
                    >
                        To provide direction, leadership, and guidance while building confidence through enhancing communication and team building skills in aspiring youth.
                    </Text>
                </VStack>
            </HStack>

            <HStack
            bg={"#C0B9DD"}
            width={"100%"}
            p={{ base: "5%", lg: "5%", "2xl": "5%" }}
            height={{ base: "40vh", lg: "90vh", "2xl": "80vh" }}
            >
                <VStack
                width={{ base: "40%", lg: "40%", "2xl": "40%" }}
                p={{ base: "5%", lg: "5%", "2xl": "5%" }}
                >
                    <Heading
                    fontFamily={"MeshedDisplay"}
                    fontSize={{ base: "4vw", lg: "4.5vw", "2xl": "3.5vw" }}
                    >
                        Our Objective
                    </Heading>
                    <Text
                    fontFamily={"NohemiLight"}
                    fontSize={{ base: "1.7vw", lg: "1.2vw", "2xl": "1.25vw" }}
                    >
                        To provide campers a safe environment to build friendships, cooperation techniques, and social skills. Through group activities, campers should learn self-discipline and communication skills to empower themselves to take responsibility for their behavior.  To encourage campers to express themselves creatively, to learn accountability, trust, and how to solve conflicts and collaborate with peers.  To strengthen their confidence in goal setting and decision making through camp activities and team-building exercises.
                    </Text>
                </VStack>
                <Image width={"60%"} src='https://cdn.testing-cylc-dfw.online/Welcome-1%20.jpg'/>
            </HStack>

            <Box
            bg={"#FFFFEA"}
            >
                <AboutFAQ/>
            </Box>
        </Box>
    );
};

export default AboutInfo;